@layer components {

  .section {
    @apply m-4;
  }

  /* forms */
  .help {
    @apply text-xs text-gray-700 dark:text-gray-300;
  }

  .app {
    @apply m-4;
  }

  .app-card {
    @apply lg:shadow-md p-4 mb-2 mt-2 lg:mt-0 lg:p-8;
  }

}

.sticky-header {
  position: sticky !important;
  background: white;
  z-index: 48;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
  /* offset the height of the navbar */
  top: 65px;
}

.dtfc-fixed-left {
  background: white;
  z-index: 47;
}