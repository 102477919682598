@layer components {
  .pg-chat-wrapper {
    @apply w-full h-full flex flex-col;
  }

  .pg-chat-pane {
    @apply flex-grow overflow-y-auto p-4 space-y-4 bg-white dark:bg-gray-700;
  }

  .pg-chat-message {
    @apply px-4 py-2 rounded-md flex items-center;
  }

  .pg-chat-message svg {
    @apply w-6 h-6;
  }

  .pg-chat-icon {
    @apply min-w-[32px] mt-1 text-right font-bold;
  }

  .pg-message-sender {
    @apply text-xs;
  }

  .pg-message-contents {
    @apply prose max-w-none lg:prose-lg ml-2;
    font-size: 16px;
    line-height: 24px;
  }

  .pg-message-contents p {
    @apply my-0;
  }

  .pg-chat-message-system {
    @apply bg-gray-100 rounded-md p-2 mb-2 flex items-center text-gray-700 border border-gray-200;
  }

  .pg-chat-message-user {
    @apply bg-green-500 text-white border border-green-600 rounded-md py-2 px-4 my-1 self-end;
  }

  .pg-chat-message-response {
    @apply bg-slate-200 border border-gray-300 rounded-md py-2 px-4 my-1 self-end;
  }

  .pg-chat-input-bar-container {
    @apply sticky bottom-0 flex-shrink-0 bg-gray-200 p-4 bg-white dark:bg-gray-800;
  }

  .pg-chat-input-bar {
    @apply w-full flex space-x-4 justify-center items-center;
  }

  /* .pg-chat-input-bar textarea {
    @apply w-full py-2 px-4 resize-none border rounded-md h-16;
    @apply mx-auto max-h-40 w-full resize-none rounded border px-2.5 py-1.5" style=" height: 72px;
  } */

  /* .pg-chat-input-bar input {
    @apply w-full py-2 px-4 border rounded-md;
  }

  .pg-chat-input-bar button {
    @apply bg-gray-800 text-white px-4 py-2 rounded-md;
  } */

  .reaction-button {
    @apply border rounded-full p-2 cursor-pointer hover:bg-slate-200;
  }
}